/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Catamaran_100Thin = require('./Catamaran_100Thin.ttf');
export const Catamaran_200ExtraLight = require('./Catamaran_200ExtraLight.ttf');
export const Catamaran_300Light = require('./Catamaran_300Light.ttf');
export const Catamaran_400Regular = require('./Catamaran_400Regular.ttf');
export const Catamaran_500Medium = require('./Catamaran_500Medium.ttf');
export const Catamaran_600SemiBold = require('./Catamaran_600SemiBold.ttf');
export const Catamaran_700Bold = require('./Catamaran_700Bold.ttf');
export const Catamaran_800ExtraBold = require('./Catamaran_800ExtraBold.ttf');
export const Catamaran_900Black = require('./Catamaran_900Black.ttf');
